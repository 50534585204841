import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function zozi(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/zozi-case1.jpg'
  const thumbAltText = 'zozi case study'
  const description = 'ZOZI is a digital marketplace for discovering and booking extraordinary experiences. Active in over 20 cities, they offer a myriad of top-flight activities all over the world, as well as the high quality gear to accompany them. We teamed up with ZOZI to establish a robust dev process, and to inject a dash of agility to their rebranding efforts and feature development.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'With our help, ZOZI managed to turn a basic platform for selling discounted experiences into a sophisticated yet intuitive e-commerce system.'

  return (
    <Layout
      location={props.location}
      title='Zozi'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <img src='/images/brands/zozi-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/zozi-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  E-Commerce, SaaS, Tourism, Travel
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  1 years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle} by='WyeWorks' />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            The leading adventure brand is backed by a host of great investors including 500 Startups, ZIG Capital, and Forerunner Ventures. After several rounds of funding, the San Francisco startup has secured over $21M in venture-backed capital, and is carving out a niche in the tech travel space with ZOZI Gurus, customized experiences lead by expert adventurers like world-renowned explorer Bear Grylls. Forbes recognized ZOZI as one America's Most Promising Companies in 2014.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            As part of the ZOZI development team, we played a fundamental role in putting in place a Sprint schedule. We established clear acceptance criteria of the work that needed to be done, and guided ZOZI on how to prioritize their backlog. We improved the website’s stability – fixing many points of failure and refining test coverage. Our devs were integral in executing the rebranding process for which we integrated design assets and refactored main flows to work seamlessly with the new design. Further, working closely with the product design agency ZURB, we helped make ZOZI’s site responsive on mobile, tablet, and desktop.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "Our partnership with WyeWorks has been a great success - their ability to jump into complex projects and make an immediate difference is impressive, and reassuring. It gives me peace of mind to know that they’ve got our back."
              </div>
              <div className='quote-block__provider-title'>
                Nate Perry-Thistle
                <br />
                VP of Engineering
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/zozi-case2.jpg' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            We accomplished a number of key milestones such as the rebranding launch, a shopping cart system, and the successful Bear Grylls campaign. Our developers worked diligently to timely deliver on all these stories and consistently meet critical press release deadlines. In all, with our help, ZOZI managed to turn a basic platform for selling discounted experiences into a sophisticated yet intuitive e-commerce system.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['crazyegg', 'codeclimate']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
